import { useState, useEffect, useContext, useCallback, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDAvatar from "components/MDAvatar";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import './assets/css/style.css';
import { setupAxiosInterceptors } from "services/interceptor";
import { useMaterialUIController, setMiniSidenav, AuthContext } from "context";
import { useMediaQuery } from '@mui/material';

// Assets (keeping these since they are static)
import defaultAvatar from './assets/images/user.webp';
import brandWhite from "assets/images/logo.webp";
import brandDark from "assets/images/logo-black.jpeg";

// Protected route stays imported as is since it's a core component
import ProtectedRoute from "examples/ProtectedRoute";

// Use lazy loading for non-essential components
const Sales = lazy(() => import("layouts/sales/Socks"));
const LotterySales = lazy(() => import("layouts/sales/Lottery"));
const Orders = lazy(() => import("layouts/orders/Socks"));
const LotteryOrders = lazy(() => import("layouts/orders/Lottery"));
const Login = lazy(() => import("auth/login"));
const Register = lazy(() => import("auth/register"));
const ForgotPassword = lazy(() => import("auth/forgot-password"));
const ResetPassword = lazy(() => import("auth/reset-password"));
const PricingPage = lazy(() => import("layouts/product"));
const WinningPrizes = lazy(() => import("layouts/winnings/winning-prizes"));
const Meeting = lazy(() => import("layouts/chat/Meeting"));
const PutCode = lazy(() => import("layouts/winnings"));
const PaymentStatus = lazy(() => import("layouts/product/payment-status"));
import useTranslation from "hooks/useTranslation";

import utilService from "services/util-service";
import AcceptPaymentRedirect from "layouts/product/accept-payment";

export default function App({ ability }) {
  console.log('Test deploy log 3')
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const authContext = useContext(AuthContext);
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [userDetails, setUserDetails] = useState({ name: "", image: "" });
  const [teems, setTeems] = useState([]);
  const [url, setUrl] = useState('');
  const decoded = authContext.getCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();


  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  }, [miniSidenav, onMouseEnter, dispatch]);

  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  }, [onMouseEnter, dispatch]);

  const handleTouchStart = useCallback((event) => {
    if (isMobile && event.touches[0].clientX < 50) {
      handleOnMouseEnter();
    }
  }, [isMobile, handleOnMouseEnter]);

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, [handleTouchStart]);

  useEffect(() => {
    // Force miniSidenav to true on initial load and authentication
    setMiniSidenav(dispatch, true);
  }, [dispatch]);

  useEffect(() => {
    if (isMobile && authContext.isAuthenticated) {
      setMiniSidenav(dispatch, true);
    }
  }, [pathname, isMobile, authContext.isAuthenticated, dispatch]);

// Reset scroll position when pathname changes
useEffect(() => {
  document.documentElement.scrollTop = 0;
  document.scrollingElement.scrollTop = 0;
}, [pathname]);

// Update user details only if authentication status changes
useEffect(() => {
  if (!authContext.isAuthenticated) return;

  const updatedDetails = {
    name: decoded.name,
    image: decoded.profile,
  };
  
  setUserDetails((prevDetails) => {
    // Only update if details have changed
    if (prevDetails.name !== updatedDetails.name || prevDetails.image !== updatedDetails.image) {
      return updatedDetails;
    }
    return prevDetails;
  });
}, [authContext.isAuthenticated]);

// Memoized fetchTeems and fetchProducts functions
const fetchTeems = useCallback(async (payload) => {
  try {
    const res = await utilService.fetchTeems(payload);
    if (res.status === "success") {
      setTeems((prevTeems) => {
        // Update only if teems data has changed
        return JSON.stringify(prevTeems) !== JSON.stringify(res.data) ? res.data : prevTeems;
      });
    }
  } catch (error) {
    console.error("Something went wrong:", error);
  }
}, []);
const fetchProducts = useCallback(async () => {
  try {
    const products = await authContext.getProducts(null);
    if(products.length > 0)
    setUrl((prevUrl) => {
      const newUrl = `${encodeURIComponent(products[0].id)}/${encodeURIComponent(products[0].name)}/${encodeURIComponent(products[0].language)}`;
      return prevUrl !== newUrl ? newUrl : prevUrl;
    });
  } catch (error) {
    console.error("Failed to fetch products:", error);
  }
}, []);

// Fetch data only when needed and dependencies change
useEffect(() => {
  if (decoded) {
    const { id: userId, role } = decoded;
    setUrl("");

    if (role === 3 && userId) {
      fetchTeems({ id: userId });
      fetchProducts();
    } else if (role === 4 && userId) {
      fetchTeems({ id: userId, IsChild: true });
    } else if (role === 6 && userId) {
      fetchProducts();
    }
  }
}, [authContext.isAuthenticated]);

// Update routes only when teems or url changes
useEffect(() => {
  if (teems.length > 0) {
    getRoutes(routes);
  }
}, [teems]);

useEffect(() => {
  getRoutes(routes);
}, [url]);

// Memoized getRoutes
const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.key === 'Sales') {
      if (decoded && decoded.role === 2) {
        route.route = null;
        route.component = null;
        route.collapse = [
          {
            route: "/sales",
            name: t("Socks"),
            key: "sales",
            component: <Sales />,
          },
          {
            route: "/lottery-sales",
            name: t("Lottery"),
            key: "lottery-sales",
            component: <LotterySales />,
          },
        ];
      } else if (decoded && decoded.role > 2) {
        route.route = "/sales";
        route.component = <Sales />;
        route.noCollapse = true;
      }
    }
    if (route.key === 'Orders') {
      if (decoded && decoded.role === 2) {
        route.route = null;
        route.component = null;
        route.collapse = [
          {
            route: "/orders",
            name: t("Socks"),
            key: "orders",
            component: <Orders />,
          },
          {
            route: "/lottery-orders",
            name: t("Lottery"),
            key: "lottery-orders",
            component: <LotteryOrders />,
          },
        ];
      } else if (decoded && decoded.role > 2) {
        route.route = "/orders";
        route.component = <Orders />;
        route.noCollapse = true;
      }
    }
    if(route.key == 'teem'){
      var transformedUsers = [];
      if(decoded && decoded.role == 3){
        transformedUsers = teems.map(user => ({
          type: "link",
          name: user.name,
          key: user.id
        }));
      }else if(decoded && decoded.role == 4){
        transformedUsers = teems.map(user => ({
          type: "button",
          name: user.name,
          key: user.id
        }));
        route.name = t("Child");
      }
      route.collapse = transformedUsers;
    }
    if(route.key == 'invite_to_collaborator'){
      if(decoded && url.length > 0 && (decoded.role == 3 || decoded.role == 6)){
        route.type = "collapse";
        route.route = '/invite-collaborator/'+url;
      }else if(url.length <= 0){
        route.type = "";
        route.route = '';
      }
    }
    if (route.key === "user-name") {
      const maxLength = 14; 
      const truncatedName = userDetails.name.length > maxLength
        ? `${userDetails.name.substring(0, maxLength)}...`
        : userDetails.name;
      route.name = truncatedName;
      route.icon = <MDAvatar src={userDetails.image} size="md" fallback={defaultAvatar} />;
    }
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={
            <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
              {route.component}
            </ProtectedRoute>
          }
          key={route.key}
        />
      );
    }

    return null;
  });
  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
          {authContext.isAuthenticated && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onTouchStart={handleTouchStart}
              />
              <Configurator />
            </>
          )}
          {/*{layout === "vr" && <Configurator />}*/}
          <Routes> 
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/register/:email/:token/:lang" element={<Register type="register" />} />
            <Route path="/join/:curl/:idc/:idu/:lang" element={<Register type="join" />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/products/:url1/:url2/:url3" element={<PricingPage />} />
            <Route path="/products/payment-status/:token" element={<PaymentStatus />} />
            <Route path="/accept-payment/nets/:token/:orderid" element={<AcceptPaymentRedirect />} />
            <Route path="/winning/prizes/:url1/:url2/:url3/:code" element={<WinningPrizes />} />
            <Route path="/winning/code" element={<PutCode />} />
            <Route path="/meeting" element={<Meeting />} />
            {getRoutes(routes)} 
            <Route path="*" element={<Navigate to="/sales" />} />
          </Routes>
      </ThemeProvider>
    </Suspense> 
  );
}
