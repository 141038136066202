import { createContext, useContext, useRef, useEffect, useMemo, useReducer, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";
import { jwtDecode } from "jwt-decode";
import productService from "services/product-service";
import prizeService from "services/prize-service";
import inviteService from "services/invite-service";
import orderService from "services/order-service";
import authService from "services/auth-service";
import CollaboratorService from 'services/collaborators-service';
import en from '../assets/translations/en.json';
import fr from '../assets/translations/fr.json';
import de from '../assets/translations/de.json';
import nl from '../assets/translations/nl.json';
import no from '../assets/translations/no.json';

import io from "socket.io-client"; // Import Socket.IO


// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext();

// the authentication context
export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => { },
  register: () => { },
  logout: () => { },
  getCurrentUser: () => { },
  getRole: () => { },
  getProducts: () => { },
  getPrize: () => { },
  getClubs: () => { },
  getTotals: () => { },
  getClubOrders: () => { },
  fetchMembers: () => { },
  getProductUrlById: () => { },
  fetchReportData: () => { },
  ChangeStatus: () => { },
  changeLanguage: () => { },
  getNameLabelData: () => { },
  getCurrentUserProfile: () => { },
  HandleMaster: () => { },
  HandleChild: () => { },
  getClubDetails: () => { },
});

const translations = { en, fr, de, nl, no };

const AuthContextProvider = ({ children }) => {
  // const socket = io(process.env.REACT_APP_API_URL);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [language, setLanguage] = useState('no');
  const [dictionary, setDictionary] = useState(translations[language]);

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token") || sessionStorage.getItem("token");

    const socketRef = useRef(null); // Create a ref for the socket
  
    // Effect to handle socket connection when user logs in
    useEffect(() => {
      const currentUser = getCurrentUser();
      if(isAuthenticated){
        if (currentUser && currentUser.id && socketRef.current === null) {
          // Initialize socket connection if user is logged in and socket is not created yet
          socketRef.current = io(process.env.REACT_APP_API_URL, { autoConnect: false }); // Disable autoConnect
    
          // Connect socket when user logs in
          socketRef.current.connect();
    
          // Join the user to the socket
          socketRef.current.emit('join', currentUser.id);
          socketRef.current.emit('fetch_user_groups', currentUser.id);
    
          // You can listen to other socket events here
          socketRef.current.on('connect', () => {
            //console.log('Socket connected');
          });
    
          socketRef.current.on('disconnect', () => {
            //console.log('Socket disconnected');
          });
        }
      }
      // Clean up the socket when user logs out or component unmounts
      return () => {
        if (socketRef.current) {
          socketRef.current.emit('leave', currentUser.id); // Optional: Emit a leave event
          socketRef.current.disconnect(); // Disconnect the socket
          socketRef.current = null; // Clean up the ref
        }
      };
    }, [isAuthenticated]);
    
    // Handle visibility change (tab switching)
    useEffect(() => {
      const handleVisibilityChange = () => {
        const currentUser = getCurrentUser(); // Get the current user
  
        if (document.hidden) {
          // If the document is hidden (tab is not visible), disconnect the socket
          if (socketRef.current) {
            socketRef.current.disconnect();
          }
        } else {
          // If the document becomes visible again, reconnect the socket
          if (socketRef.current && currentUser?.id) {
            socketRef.current.connect();
            socketRef.current.emit('join', currentUser.id); // Rejoin after reconnecting
          }
        }
      };
  
      // Listen to the visibility change event
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);
  
    // Handle window focus and blur events for minimizing and maximizing
    useEffect(() => {
      const handleWindowFocus = () => {
        const currentUser = getCurrentUser();
        if (socketRef.current && currentUser?.id && !socketRef.current.connected) {
          socketRef.current.connect();
          socketRef.current.emit('join', currentUser.id);
        }
      };
  
      const handleWindowBlur = () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
      };
  
      window.addEventListener('focus', handleWindowFocus);
      window.addEventListener('blur', handleWindowBlur);
  
      // Cleanup event listeners on unmount
      return () => {
        window.removeEventListener('focus', handleWindowFocus);
        window.removeEventListener('blur', handleWindowBlur);
      };
    }, []);

  useEffect(() => {
    setDictionary(translations[language]);
  }, [language]);

  useEffect(() => {
    if (token) {
      changeLanguage(getCurrentUser().language)
    } else {
      changeLanguage('no')
    }
  }, [token])

  useEffect(() => {
    if (!token) return;

    setIsAuthenticated(true);
    navigate(location.pathname);
  }, []);

  useEffect(() => {
    if (!token) return;

    navigate(location.pathname);
  }, [isAuthenticated]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    // console.log(lang)
    // localStorage.setItem("ekofib-language", lang);
  };

  const login = (newToken, rememberMe) => {
    if (rememberMe) {
      localStorage.setItem("token", newToken);
    } else {
      sessionStorage.setItem("token", newToken);
    }
    const lang = getCurrentUser().language;
    setIsAuthenticated(true);
    changeLanguage(lang);
    navigate("/dashboard/analytics");
  };

  const validateCurrentPassword = async (password) => {
    try {
      const response = await authService.login({ password });
      return response.data.isValid;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logout = () => {
    const currentUser = getCurrentUser();
    if (socketRef.current && currentUser?.id) {
      socketRef.current.emit('leave', currentUser.id); // Optional: Inform server the user has left
      socketRef.current.disconnect(); // Disconnect the socket
    }
    socketRef.current = null; // Reset the socket reference
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/auth/login");
  };

  const getCurrentUser = () => {
    try {
      const token = localStorage.getItem("token") || sessionStorage.getItem("token");
      if (token) {
        const decoded = jwtDecode(token);
        return decoded;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const getCurrentUserProfile = async () => {
    try {
      const id = getCurrentUser().id;
      // second I get the user with role
      const res = await authService.getProfile(id);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getRole = async () => {
    // first get the current user id
    const id = await getCurrentUser();
    try {
      // second I get the user with role
      const res = await CrudService.getUser(id);
      const roleId = res.data.relationships.roles.data[0].id;
      // third check the role id and return the role type
      if (roleId === "1") {
        return "admin";
      }
      if (roleId === "2") {
        return "creator";
      }
      if (roleId === 3) {
        return "member";
      }
      return res.included[0].attributes.name;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getProducts = async (search, type) => {
    // first get the current user id
    const id = getCurrentUser().id;
    try {
      // second I get the user with role
      const res = await productService.getProduct(id, search, type);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getPrize = async (search) => {
    try {
      // second I get the user with role
      const res = await prizeService.getPrize(search);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getClubs = async (query, type) => {
    // first get the current user id
    const id = getCurrentUser().id;
    try {
      // second I get the user with role
      const res = await orderService.getClubs(id, query, type);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getTotals = async (id, type, userId, role, order) => {
    try {
      // second I get the user with role
      const res = await orderService.getTotals({ id, role, type, userId, order });

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getClubOrders = async (payload) => {
    try {
      // second I get the user with role
      const res = await orderService.getClubOrders(payload);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const fetchMembers = async (payload) => {
    try {
      // second I get the user with role
      const res = await orderService.fetchMembers(payload);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const fetchReportData = async (payload) => {
    try {
      // second I get the user with role
      const res = await orderService.fetchReportData(payload);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const ChangeStatus = async (payload) => {
    try {
      // second I get the user with role
      const res = await orderService.ChangeStatus(payload);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getNameLabelData = async (payload) => {
    try {
      // second I get the user with role
      const res = await orderService.fetchNameLabelData(payload);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getProductUrlById = async (id) => {
    try {
      // second I get the user with role
      const res = await inviteService.getUrl(id);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const getClubDetails = async (id) => {
    try {
      // second I get the user with role
      const res = await orderService.getClubDetails(id);

      return res; // Return the parsed data
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const HandleMaster = async (id, userIn) => {
    const uid = getCurrentUser().id
    const Eiaa = localStorage.getItem("EkofibIsAccessingAccount");

    try {
      const data = await CollaboratorService.ChangeUser({ uid, id, Eiaa, userIn });

      if (data.status == "success") {
        if (data.token) {
          if ((Eiaa == "true" || Eiaa == true) && userIn == false && data.masterId == 0) {
            localStorage.removeItem("EkofibIsAccessingAccount")
          } else {
            localStorage.setItem("EkofibIsAccessingAccount", true)
          }
          localStorage.setItem("token", data.token);
          setIsAuthenticated(Date.now());
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Something Went wrong:", error);
    }
  }

  const HandleChild = async (id) => {
    try {
      const data = await CollaboratorService.ChangeChild({ id });

      if (data.status == "success") {
        if (data.token) {
          localStorage.setItem("token", data.token);
          setIsAuthenticated(Date.now());
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Something Went wrong:", error);
    }
  }

  return (
    <AuthContext.Provider value={
      {
        isAuthenticated, setIsAuthenticated,
        login, validateCurrentPassword, logout, getRole, getCurrentUser, getProducts, getPrize, getClubs, getTotals, getClubOrders,
        getProductUrlById, fetchMembers, fetchReportData, ChangeStatus, getNameLabelData,
        getCurrentUserProfile, HandleMaster, HandleChild, changeLanguage, dictionary, getClubDetails, socket: socketRef.current
      }}>
      {children}
    </AuthContext.Provider>
  );
};

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  //const savedMiniSidenav = localStorage.getItem("miniSidenav");
  const initialState = {
    miniSidenav: true,//(savedMiniSidenav)?JSON.parse(savedMiniSidenav):false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "warning",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "vr",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  AuthContextProvider,
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
